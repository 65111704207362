import { PropsWithChildren, useEffect } from 'react';

import { AuthStatus, useAuthUser } from 'app/providers/AuthUserProvider';

import { logger } from 'shared/services/logger';

export const PrivateRoute = ({ children }: PropsWithChildren) => {
  const {
    models: { authenticated, authStatus },
  } = useAuthUser();

  useEffect(() => {
    if (!authenticated && authStatus !== AuthStatus.INITIAL) {
      const tags = {
        url: window.location.hostname + window.location.pathname,
      };

      logger.error(new Error('Tried to access private route without a token'), { tags });
    }
  }, [authenticated, authStatus]);

  return authenticated ? children : null;
};
