type RouteConfig = {
  url: string;
  isPrivate?: boolean;
};

export const PUBLIC_ROUTES: Record<string, RouteConfig> = {
  RESET_PASSWORD: {
    url: '/reset-password',
  },
} as const;

export const PRIVATE_ROUTES: Record<string, RouteConfig> = {
  INSTALL_APP: {
    // url: '/install-app',
    // while there is no cabinet functionality - show install app page as home
    url: '/',
    isPrivate: true,
  },
  ANDROID_DISCLAIMER: {
    url: '/android-disclaimer',
    isPrivate: true,
  },
  // HOME: {
  //   url: '/',
  // },
} as const;

export const ROUTES = {
  ...PRIVATE_ROUTES,
  ...PUBLIC_ROUTES,
} as const;
