import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthToken } from 'app/providers/AuthTokenProvider';

import {
  Usersfunnelprofilemodelresponse,
  useGetUsersFunnelProfile,
  getGetUsersFunnelProfileQueryKey,
} from 'shared/api/forerunner';
import { logger } from 'shared/services/logger';

import { AuthStatus } from './constants';

export interface UserModel extends Usersfunnelprofilemodelresponse {}

interface AuthUserContextInterface {
  models: {
    user: UserModel | null;
    authenticated: boolean;
    authStatus: AuthStatus;
  };
  operations: {
    signout: () => void;
  };
}

const AuthUserContext = React.createContext<AuthUserContextInterface | null>(null);

function useAuthUser() {
  const context = React.useContext(AuthUserContext);

  if (!context) {
    throw new Error(`useAuthUser must be used within Auth`);
  }

  return context;
}

interface AuthProps {
  children?: React.ReactNode;
}

const AuthUserProvider = ({ children }: AuthProps): JSX.Element => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.INITIAL);

  const { token, isReady, setToken } = useAuthToken();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isReady && authStatus === AuthStatus.INITIAL) {
      setAuthStatus(token ? AuthStatus.PENDING : AuthStatus.DONE);
    }
  }, [authStatus, token, isReady]);

  const queryKey = useMemo(() => [...getGetUsersFunnelProfileQueryKey(), token], [token]);

  const { data: user = null } = useGetUsersFunnelProfile({
    query: {
      staleTime: Infinity,
      retry: 2,
      enabled: Boolean(token),
      queryKey,
      onSuccess: () => {
        setAuthStatus(AuthStatus.DONE);
      },
      onError: (error) => {
        if (typeof error.response?.status === 'number' && error.response.status < 500) {
          const tags = {
            'app.component': 'AuthUserProvider',
          };

          logger.error(Error('Auth error'), { cause: error }, { tags });
          signout();
        }

        setAuthStatus(AuthStatus.DONE);
      },
    },
  });

  const signout = useCallback(() => {
    queryClient.clear();
    setToken('');
  }, [queryClient, setToken]);

  const api = useMemo<AuthUserContextInterface>(
    () => ({
      models: {
        user,
        authenticated: Boolean(token),
        authStatus,
      },
      operations: {
        signout,
      },
    }),
    [user, token, authStatus, signout]
  );

  return <AuthUserContext.Provider value={api}>{children}</AuthUserContext.Provider>;
};

export { AuthUserProvider, useAuthUser };
