import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from 'shared/components/ErrorBoundary';
import { PrivateRoute } from 'shared/components/PrivateRoute/PrivateRoute';

import { useDocumentSocialWebViewBrowser } from 'shared/hooks/useDocumentOperations';

import { ROUTES } from 'shared/constants/routes';

import { withProviders } from './providers/withProviders';

const AndroidDisclaimer = lazy(() => import('pages/android-disclaimer'));
// const Home = lazy(() => import('pages/home'));
const ResetPassword = lazy(() => import('pages/reset-password'));
const ThankYouPage = lazy(() => import('pages/install-app'));

const AppComponent = () => {
  useDocumentSocialWebViewBrowser();

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Routes>
          {/* <Route path={ROUTES.HOME.url} element={<Home />} /> */}
          <Route path={ROUTES.RESET_PASSWORD.url} element={<ResetPassword />} />
          <Route
            path={ROUTES.INSTALL_APP.url}
            element={
              <PrivateRoute>
                <ThankYouPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.ANDROID_DISCLAIMER.url}
            element={
              <PrivateRoute>
                <AndroidDisclaimer />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export const App = withProviders(AppComponent);
